import { useTopicStore } from '~/stores/topic'
import { useDeviceStore } from '~/stores/device'

export default defineNuxtRouteMiddleware(async (to) => {
  const topicStore = useTopicStore()
  const deviceStore = useDeviceStore()
  
  const { brand, model, section, topic } = to.params
  
  if (!deviceStore.device) {
    await deviceStore.fetchDevice(brand, model)
  }

  if (brand && model && section) {
    let topicResponse = null

    if (topic) {
      await topicStore.fetchTopic(brand, model, topic)
    
      topicResponse = topicStore.topic
    }

    const currentSlugFromHistory = deviceStore.content.find(el => el.slugHistory?.includes(section))

    if (currentSlugFromHistory && currentSlugFromHistory.slug !== section) {
      const mainRoutePath = [brand, model, currentSlugFromHistory.slug].join('/')

      const nextPath = topicResponse ? `/${mainRoutePath}/${topicResponse?.seo.slug}` : `/${mainRoutePath}`
      const nextRoute = { path: nextPath, query: {}, hash: to.hash }

      return navigateTo(nextRoute, { redirectCode: 301 })
    }

    if (topicResponse?.redirectTo) {
      const mainRoutePath = [brand, model, section].join('/')

      const nextPath = `/${mainRoutePath}/${topicResponse.redirectTo}`
      const nextRoute = { path: nextPath, query: {}, hash: to.hash }

      return navigateTo(nextRoute, { redirectCode: 301 })
    }
  }
})
  