export const useDeviceStore = defineStore('deviceStore', () => {
  const device = ref<Product | null>(null)
  const mostViewedContent = ref<MostViewedContent[]>([])
  const content = ref<DeviceContentChapter[]>([])
  const specifications = ref<ComparisonModel[]>([])

  async function fetchDevice(brand: string, model: string) {
    try {
      if (device.value?.slug === model) return

      const data = await $fetch(`/api/selfservice/devices/${brand}/${model}`)
      device.value = data.device
      content.value = data.content
      mostViewedContent.value = data.mostViewedContent
      specifications.value = data.specs
      return data
    } catch (error) {
      console.log(error)
    }
  }

  return {
    device,
    content,
    mostViewedContent,
    specifications,
    fetchDevice,
  }
})
