import revive_payload_client_4sVQNw7RlN from "/home/appveyor/projects/o2-uk/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/appveyor/projects/o2-uk/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/appveyor/projects/o2-uk/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/appveyor/projects/o2-uk/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/appveyor/projects/o2-uk/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/home/appveyor/projects/o2-uk/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/appveyor/projects/o2-uk/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/appveyor/projects/o2-uk/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/appveyor/projects/o2-uk/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/home/appveyor/projects/o2-uk/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/appveyor/projects/o2-uk/.nuxt/sentry-client-config.mjs";
import plugin_client_UYiXMU8ZyN from "/home/appveyor/projects/o2-uk/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import titles_dw2T9lEw4h from "/home/appveyor/projects/o2-uk/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import slideovers_X7whXyrIWR from "/home/appveyor/projects/o2-uk/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/appveyor/projects/o2-uk/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/appveyor/projects/o2-uk/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/appveyor/projects/o2-uk/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/appveyor/projects/o2-uk/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sampling_client_1kJbP4vIsX from "/home/appveyor/projects/o2-uk/plugins/sampling.client.ts";
import sentry_client_shVUlIjFLk from "/home/appveyor/projects/o2-uk/plugins/sentry.client.ts";
import tealium_client_jAgoafq9kJ from "/home/appveyor/projects/o2-uk/plugins/tealium.client.ts";
import defaults_aRl2YLnTcb from "/home/appveyor/projects/o2-uk/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_client_UYiXMU8ZyN,
  titles_dw2T9lEw4h,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  sampling_client_1kJbP4vIsX,
  sentry_client_shVUlIjFLk,
  tealium_client_jAgoafq9kJ,
  defaults_aRl2YLnTcb
]